import type { ManifestDashboard } from '@umbraco-cms/backoffice/extension-registry';

export const manifests: Array<ManifestDashboard> = [
    {
        type: 'dashboard',
        alias: 'my.welcome.dashboard',
        name: 'My.WelcomePackage',
        js: () => import('./src/welcome-dashboard.element.js'),
        weight: 1,
        elementName: 'my-welcome-dashboard',
        meta: {
            "label": "Velkommen til Gutenberg",
            "pathname": "welcome-dashboard"
        },
        conditions: [
            {
                "alias": "Umb.Condition.SectionAlias",
                "match": "Umb.Section.Content"
            }
        ]
    }
];