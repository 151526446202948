import type { ManifestTinyMcePlugin } from '@umbraco-cms/backoffice/extension-registry';

export const manifests: Array<ManifestTinyMcePlugin> = [
	{
		type: 'tinyMcePlugin',
		alias: 'Umb.TinyMcePlugin.Equation',
		name: 'Equation Editor TinyMCE Plugin',
		js: () => import('./src/eqneditor-plugin.js'),
		meta: {
			toolbar: [
				{
					alias: 'eqneditor',
					label: 'eqneditor',
					icon: 'icon-list',
				},
			],			
			config: {
				propertyEditorSchemaAlias: 'Umbraco.TextBox',
				keep_styles: false
			}
		},
	}
];
