import type { ManifestTinyMcePlugin } from '@umbraco-cms/backoffice/extension-registry';

export const manifests: Array<ManifestTinyMcePlugin> = [
    {
        type: 'tinyMcePlugin',
        alias: 'Umb.TinyMcePlugin.TextChange',
        name: 'Text Change TinyMCE Plugin',
        js: () => import('./src/textchange.js'),
        meta: {
            toolbar: [
                {
                    alias: 'flite',
                    label: 'Text Change',
                    icon: 'flite',
                },
            ],
            config: {
                plugins: "quickbars flite autolink lists link image charmap anchor code media table powerpaste advlist",
                menubar: false,
                default_link_target: "_blank",
                toolbar_mode: "wrap",
                resize: true,
                statusbar: true,
                external_plugins: { flite: "/App_Plugins/gutenberg/flite/flite/plugin.min.js" },
                flite: {
                    contextMenu: true,
                    isTracking: true,
                    isVisible: true,
                    user: {
                        name: "default",
                        id: "007"
                    },
                    debug: {
                        error: true,
                        warn: true
                    },
                    tooltips: {
                        show: true,
                        template: "%a by %u, first edit %t, last edit %T"
                    }
                },
                contextMenu: "link flite flite-acceptall flite-rejectall flite-acceptone flite-rejectone",
                quickbars_insert_toolbar: false,
                quickbars_selection_toolbar: 'bold italic | flite-acceptone flite-rejectone quicklink | ignoreglossary',
                toolbar:
                'styles | flite | bold italic superscript subscript eqneditor | removeformat bullist numlist indent outdent | link charmap addImage addFileLink table | umbmediapicker code',
                font_formats: 'Merriweather;PalatinoBold;PalatinoItalic;Palatino',
                advlist_bullet_styles: 'disc',
                advlist_number_styles: 'decimal,lower-alpha,lower-roman,upper-alpha,upper-roman',
                
                powerpaste_word_import: 'clean',
                powerpaste_html_import: 'clean',
                style_formats: [
                    {
                        title: 'Headings',
                        items: [
                            {
                                title: 'Heading 1',
                                format: 'h1'
                            },
                            {
                                title: 'Heading 2',
                                format: 'h2'
                            },
                            {
                                title: 'Heading 3',
                                format: 'h3'
                            },
                            {
                                title: 'Heading 4',
                                format: 'h4'
                            },
                            {
                                title: 'Heading 5',
                                format: 'h5'
                            },
                            {
                                title: 'Heading 6',
                                format: 'h6'
                            }
                        ]
                    },
                    {
                        title: 'Inline',
                        items: [
                            {
                                title: 'Bold',
                                format: 'bold'
                            },
                            {
                                title: 'Italic',
                                format: 'italic'
                            },
                            {
                                title: 'Superscript',
                                format: 'superscript'
                            },
                            {
                                title: 'Subscript',
                                format: 'subscript'
                            }
                        ]
                    },
                    {
                        title: 'Blocks',
                        items: [
                            {
                                title: 'Paragraph',
                                format: 'p'
                            },
                            {
                                title: 'Blockquote',
                                format: 'blockquote'
                            },
                        ]
                    },
                    {
                        title: 'Settings',
                        items: [
                            {
                                title: 'Ignore glossary',
                                inline: 'span',
                                classes: 'ignoreglossary'
                            }
                        ]
                    }
                ]
            }
        }
    }
];