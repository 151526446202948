import type { ManifestWorkspaceAction, ManifestWorkspaceContext } from '@umbraco-cms/backoffice/extension-registry';

export const manifests: Array<ManifestWorkspaceAction | ManifestWorkspaceContext> = [
	{
		type: 'workspaceContext',
		name: 'Text Change Context',
		alias: 'local.textChange.context',
		api: () => import('./text-change-context.js'),
		conditions: [
			{
				alias: 'Umb.Condition.WorkspaceAlias',
				match: 'Umb.Workspace.Document',
			},
		],
	},
	{
		type: 'workspaceAction',
		kind: 'default',
		name: 'Text Change Toggler',
		alias: 'local.textChange.toggler',
		weight: 1000,
		api: () => import('./text-change-toggler.js'),
		meta: {
			label: 'Text changes',
			look: 'primary',
			color: 'lightblue',
		},
		conditions: [
			{
				alias: 'Umb.Condition.WorkspaceAlias',
				match: 'Umb.Workspace.Document',
			},
		],
	}
];
