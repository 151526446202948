import { manifests as customviews } from './customviews/manifest.js';
import { manifests as eqneditor } from './eqneditor/manifest.js';
import { manifests as textchange } from './textchange/manifest.js';
import { manifests as dashboard } from './dashboards/manifest.js';
import { manifests as textChangeToggler } from './workspaceaction/manifest.js';
//import { manifests as praxisauth } from './praxisauthpackage/manifest.js';

export const manifests = [
    ...customviews,
    ...eqneditor,
    ...textchange,
    ...dashboard,
    ...textChangeToggler,
    //...praxisauth

];