import type { ManifestBlockEditorCustomView } from '@umbraco-cms/backoffice/extension-registry';

export const manifests: Array<ManifestBlockEditorCustomView> = [
    {
        type: 'blockEditorCustomView',
        alias: 'Umb.blockEditorCustomView.TextView',
        name: 'Block Editor Custom View Text',
        element: () => import('./src/text.js'),
        forContentTypeAlias: 'webbog_contentPart_text',
        forBlockEditor: 'block-grid'
    },
    {
        type: 'blockEditorCustomView',
        alias: 'Umb.blockEditorCustomView.ImageView',
        name: 'Block Editor Custom View Image',
        element: () => import('./src/image.js'),
        forContentTypeAlias: 'webbog_contentPart_Image',
        forBlockEditor: 'block-grid'
    },
    {
        type: 'blockEditorCustomView',
        alias: 'Umb.blockEditorCustomView.VideoView',
        name: 'Block Editor Custom View Video',
        element: () => import('./src/video.js'),
        forContentTypeAlias: 'webbog_contentPart_Video',
        forBlockEditor: 'block-grid'
    },
    {
        type: 'blockEditorCustomView',
        alias: 'Umb.blockEditorCustomView.GalleryView',
        name: 'Block Editor Custom View Gallery',
        element: () => import('./src/gallery.js'),
        forContentTypeAlias: 'webbog_contentPart_imageGallery',
        forBlockEditor: 'block-grid'
    },
    {
        type: 'blockEditorCustomView',
        alias: 'Umb.blockEditorCustomView.AudioView',
        name: 'Block Editor Custom View Audio',
        element: () => import('./src/audio.js'),
        forContentTypeAlias: 'webbog_contentpart_audio',
        forBlockEditor: 'block-grid'
    },
    {
        type: 'blockEditorCustomView',
        alias: 'Umb.blockEditorCustomView.DecorationImageView',
        name: 'Block Editor Custom View Decoration Image',
        element: () => import('./src/decorationImage.js'),
        forContentTypeAlias: 'webbog_pageDecoration_Image',
        forBlockEditor: 'block-grid'
    },
    {
        type: 'blockEditorCustomView',
        alias: 'Umb.blockEditorCustomView.IframeView',
        name: 'Block Editor Custom View iframe',
        element: () => import('./src/iframe.js'),
        forContentTypeAlias: 'webbog_contentpart_iframe',
        forBlockEditor: 'block-grid'
    },
    {
        type: "blockEditorCustomView",
        alias: "Local.blockEditorCustomView.webbog_contentLayout_twoColumns",
        name: "Block Editor Custom View for 2 kollonne",
        element: () => import('./src/twocolumns.js'),
        forContentTypeAlias: "webbog_contentLayout_twoColumns",
        forBlockEditor: "block-grid",
    },
    {
        type: 'blockEditorCustomView',
        alias: 'Umb.blockEditorCustomView.MoodleQuizView',
        name: 'Block Editor Custom View moodleQuiz',
        element: () => import('./src/moodlequiz.js'),
        forContentTypeAlias: 'webbog_contentPart_moodleQuiz',
        forBlockEditor: 'block-grid'
    },
    {
        type: 'blockEditorCustomView',
        alias: 'Umb.blockEditorCustomView.Title',
        name: 'Block Editor Custom View Title',
        element: () => import('./src/title.js'),
        forContentTypeAlias: 'webbog_contentPart_title',
        forBlockEditor: 'block-grid'
    },
    {
        type: 'blockEditorCustomView',
        alias: 'Local.blockEditorCustomView.webbog_contentLayout_boxfullwidth',
        name: 'Block Editor Custom View for Box Full Width',
        element: () => import('./src/boxfullwidth.js'),
        forContentTypeAlias: "webbog_contentLayout_boxfullwidth",
        forBlockEditor: "block-grid"
    },
    {
        type: 'blockEditorCustomView',
        alias: 'Local.blockEditorCustomView.webbog_contentLayout_boxhalfwidth',
        name: 'Block Editor Custom View for Box Half Width',
        element: () => import('./src/boxhalfwidth.js'),
        forContentTypeAlias: "webbog_contentLayout_boxhalfwidth",
        forBlockEditor: "block-grid"
    },
    {
        type: "blockEditorCustomView",
        alias: "Local.blockEditorCustomView.webbog_contentLayout_assignment",
        name: "Block Editor Custom View for Assignment",
        element: () => import('./src/assignment.js'),
        forContentTypeAlias: "webbog_contentLayout_assignment",
        forBlockEditor: "block-grid",
    },
    {
        type: "blockEditorCustomView",
        alias: "Local.blockEditorCustomView.webbog_contentLayout_guide",
        name: "Block Editor Custom View for Guide",
        element: () => import('./src/guide.js'),
        forContentTypeAlias: "webbog_contentLayout_guide",
        forBlockEditor: "block-grid",
    }
];